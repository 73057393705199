import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify, { Auth } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { aws_exports } from './environments/awsexport.dev';
import { environment } from './environments/environment';

Amplify.configure(aws_exports);
Amplify.configure({
  API: {
    graphql_headers: async () => ({
      'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
  }),
  },
});
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
