  <div fxLayout="column">

    <div fxFlex class="cardHeader" fxLayout="row" fxLayoutAlign="space-between center"
      style="margin-bottom: 10px;">
      Qualification Filter
      <mat-icon style="font-size: 30px;color: #5D60A5;cursor: pointer;" (click)="dialogRef.close(qualification)">close</mat-icon>
    </div>
    <div style="margin-top: 10px;">
      <mat-divider></mat-divider>
    </div>

    <mat-dialog-content>
        <p style="font-family: nunito; font-size: 14px;padding-top: 10px;">
            Have fun playing around with the Qualification Filter to see the jobs that you may do based on the tertiary qualifications you plan to study.
            Select different qualification levels, and look at how this changes your job list.
            The qualifications in the Job Descriptions are examples only. You may find other relevant qualifications for the jobs you choose.
            <br/><br/>
            It is better to strive to complete a higher qualification than specified in the job description.
            <br/>
        </p>

        <div *ngFor="let qualification of allQualifications" style="width: 100%;" class="p-12 m-0" fxLayout="column" >
          <div fxFlex  fxLayout="row" fxLayoutAlign="space-between center" style="padding: 10px;">
            {{qualification.name}}
          <mat-checkbox [(ngModel)]="qualification.state" (ngModelChange)="filterChanged(qualification)"></mat-checkbox>
        </div>
        </div>
    </mat-dialog-content>

  </div>
