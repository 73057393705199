import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { JobDescriptionService } from 'src/app/services/job-description.service';

@Component({
  selector: 'app-qualification-filter',
  templateUrl: './qualification-filter.component.html',
  styleUrls: ['./qualification-filter.component.scss'],
})
export class QualificationFilterComponent implements OnInit {
  qualificationArray = [{name: 'All', state: false},{name: 'Grade 12', state: false},{name: 'Bachelor of Technology', state: false},{name: 'Trade', state: false},{name: 'Trade Test', state: false},{name: 'Grade 12 + Certificate Courses', state: false},{name: 'National Diploma', state: false},{name: 'University Degree Honours', state: false},{name: 'University Degree Masters', state: false}];
  qualification = [];
  allQualifications = [];
  errorMessage:  any;
  @Output() search = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<QualificationFilterComponent>,
    private jobService: JobDescriptionService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    
  ngOnInit() {
    this.qualification = [];
    if(JSON.parse(localStorage.getItem("qualifications"))){
      this.allQualifications = JSON.parse(localStorage.getItem("qualifications"));
      this.allQualifications.unshift({name: 'All', state: false});
      for(let qualification of this.allQualifications){
        qualification.state = false;
      }
    }else {
      this.errorMessage = "Please hold";
      from(this.jobService.getListOfQualifications()).subscribe((res: any) => {
        this.errorMessage = "";
        this.allQualifications = res.data.listQualifications.items;
        this.allQualifications.unshift({name: 'All', state: false});
        for(let qualification of this.allQualifications){
          qualification.state = false;
        }
      },(error) => {
        this.errorMessage = "Failed to retrieve qualifications, please try again later";
      })
    }
  }

  filterChanged(event){
    if(event.name === "All" && event.state === true){
      for(let qual of this.allQualifications) {
        qual.state = true;
        this.qualification.push(qual);
      }
    }else if (event.name === "All" && event.state === false){
      for(let qual of this.allQualifications) {
        qual.state = false;
        this.qualification = [];
      }
    }else {
      if(event.state){
        this.qualification.push(event);
      }else {
        const index = this.qualificationArray.indexOf(event);
        this.qualification.splice(index,1);
      }
    }
    this.search.emit(this.qualification);
  }
}
