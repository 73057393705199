import { Platform } from '@angular/cdk/platform';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, AlertController, MenuController } from '@ionic/angular';
import { ScreenSizeService } from '../services/screensize.service';

export interface Page {
  icon: string;
  title: string;
  route: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }
}
