import { BaseModule } from './../core/base/base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { IonicModule } from '@ionic/angular';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../core/shared/material/material.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatDividerModule,BaseModule
  ],
  exports: [MenuComponent]
})
export class MenuModule { }
