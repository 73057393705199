const ASSESSMENT_BASE_PATH = "https://nrfskq5yqzga3jpqucuuo732fm.appsync-api.eu-west-1.amazonaws.com/graphql";
const JOB_SERVICE_BASE_PATH = "https://r6xqwhf6u5cftfjhh55mikknfi.appsync-api.eu-west-1.amazonaws.com/graphql";
const AUTH_SERVICE_BASE_PATH = "https://l251t9t6v9.execute-api.eu-west-1.amazonaws.com/Prod/v1";

export const environment = {
  production: false,
  api: {
    assessment: ASSESSMENT_BASE_PATH,
    jobService: JOB_SERVICE_BASE_PATH,
    authService: AUTH_SERVICE_BASE_PATH
  }
};
