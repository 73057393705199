import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/core/shared/material/material.module';
import { QualificationFilterComponent } from './qualification-filter.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [QualificationFilterComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [
    QualificationFilterComponent
  ],
  entryComponents: [
    QualificationFilterComponent
  ]
})
export class QualificationFilterModule { }
