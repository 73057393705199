
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import {Auth } from 'aws-amplify';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    token: any;
    constructor(public router: Router) { 
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(localStorage.getItem('authorization')){
            request = request.clone({
                setHeaders: {
                    Signature: localStorage.getItem('authorization'),
                    'Content-Type': 'application/octet-stream',
                }
            });
        }
        return next.handle(request);
    }
}
