import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobDescriptionService {
    jobDescriptionApi = environment.api.jobService;
    jobDescription = {
      "aws_appsync_graphqlEndpoint" : this.jobDescriptionApi
    }

  constructor() { }

  getJobDescriptions(themeCode,assessmentTypeId){
    API.configure(this.jobDescription);
    return API.graphql({
        query: gql`
        query MyQuery($themeCode: String = "", $assessmentTypeId: String = "") {
          listJobDescriptionsForThemeCode(themeCode: $themeCode, assessmentTypeId: $assessmentTypeId) {
            items {
              matchScore
              seniorityLevel
              themeCode
              jobDescription {
                id
                jobTitle
              }
              jobFamily {
                id
                name
              }
              qualificationRequirements {
                qualification {
                  id
                  name
                }
                qualificationRequirement {
                  id
                  name
                }
              }
              scarceSkill
              fourIR
            }
          }
        }
        `,variables : {
            themeCode: themeCode,
            assessmentTypeId: assessmentTypeId
        }
      })  
  }

  getJobDescriptionsForUser() {
    API.configure(this.jobDescription);
    return API.graphql({
        query: gql`
        query MyQuery {
          listJobDescriptionsForUser {
            items {
              matchScore
              seniorityLevel
              themeCode
              jobDescription {
                id
                jobTitle
              }
              jobFamily {
                id
                name
              }
              qualificationRequirements {
                qualification {
                  id
                  name
                }
                qualificationRequirement {
                  id
                  name
                }
              }
              scarceSkill
              fourIR
            }
          }
        }
        `,variables : { }
      })
  }

  getJobDescriptionById(id){
    API.configure(this.jobDescription);
    return API.graphql({
        query: gql`
        query MyQuery($id: ID = "") {
          getJobDescription(id: $id) {
            additionalQualifications
            fourIR
            id
            jobDuties
            jobExperience
            jobPurpose
            jobSkills
            jobTitle
            scarceSkill
            subTitle
            themeCode
            coreCompetencies {
              id
              name
            }
            explanationTheme {
              commissionBasedRemuneration
              salesTargetDriven
              flexiHours {
                id
                name
              }
              ownBusinessPotential {
                id
                name
              }
              timeAtDesk {
                id
                name
              }
              timeWithClients {
                id
                name
              }
              travelRequirement {
                id
                name
              }
              workFromHome {
                id
                name
              }
            }
            jobFamily {
              id
              name
            }
            jobSectors {
              id
              name
            }
            qualificationRequirements {
              qualification {
                id
                name
              }
              qualificationRequirement {
                id
                name
              }
            }
            seniority {
              id
              name
            }
            subjectComment
            subjectRequirements {
              subject {
                name
              }
              subjectRequirement {
                name
              }
            }
            assessmentTypes {
              assessmentType {
                id
                name
              }
              description
            }
            studyInstitutions {
              studyInstitution
              studyInstitutionRelevance {
                id
                name
              }
            }
          }
        }
        
        
        `,variables : {
            id: id
        }
      }) 
  }

  getListOfQualifications(){
    API.configure(this.jobDescription);
    return API.graphql({
      query: gql`
      query MyQuery {
        listQualifications {
          items {
            id
            name
          }
        }
        listStudyInstitutionRelevances {
          items {
            id
            name
            value
          }
        }
      }         
      `, variables : {}
    }) 
  }
}



